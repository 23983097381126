import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import http from '../_api/http'

const AdmissionForm = () => {
  const [values, setValues] = useState({
    acandemic_year: '',
    admission_class: '',
    sms_number: '',
    email: '',
    student_name: '',
    father_name: '',
    surname: '',
    marksheet_std_name: '',
    gender: '',
    std_bod: '',
    std_mother_name: '',
    previous_school_name: '',
    source_of_enquiry: '',
    reference_by: '',
    std_address: ''
  })

  const [validations, setValidations] = useState({
    acandemic_year: '',
    admission_class: '',
    sms_number: '',
    email: '',
    student_name: '',
    father_name: '',
    surname: '',
    marksheet_std_name: '',
    gender: '',
    std_bod: '',
    std_mother_name: '',
    previous_school_name: '',
    source_of_enquiry: '',
    reference_by: '',
    std_address: ''
  })

  const validateAll = () => {
    const {
      acandemic_year,
      admission_class,
      sms_number,
      email,
      student_name,
      father_name,
      surname,
      marksheet_std_name,
      gender,
      std_bod,
      std_mother_name,
      previous_school_name,
      source_of_enquiry,
      reference_by,
      std_address
    } = values
    const validations = {
      acandemic_year: '',
      admission_class: '',
      sms_number: '',
      email: '',
      student_name: '',
      father_name: '',
      surname: '',
      marksheet_std_name: '',
      gender: '',
      std_bod: '',
      std_mother_name: '',
      previous_school_name: '',
      source_of_enquiry: '',
      reference_by: '',
      std_address: ''
    }
    let isValid = true

    if (!acandemic_year) {
      validations.acandemic_year = 'Please select any one'
      isValid = false
    }

    if (!admission_class) {
      validations.admission_class = 'Please select any one'
      isValid = false
    }

    if (!sms_number) {
      validations.sms_number = 'Please Enter phone number'
      isValid = false
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = 'Email format must be as example@mail.com'
      isValid = false
    }

    if (!student_name) {
      validations.student_name = 'Please Enter student name'
      isValid = false
    }
    if (!father_name) {
      validations.father_name = 'Please Enter student father name'
      isValid = false
    }
    if (!surname) {
      validations.surname = 'Please Enter student surname'
      isValid = false
    }
    if (!marksheet_std_name) {
      validations.marksheet_std_name =
        'Please Enter student name as per your marksheet'
      isValid = false
    }

    if (!gender) {
      validations.gender = 'Please select any one'
      isValid = false
    }

    // if (!std_bod) {
    //   validations.std_bod = 'Please Select student birthday'
    //   isValid = false
    // }
    // if (!std_mother_name) {
    //   validations.std_mother_name = 'Please Enter student mother name'
    //   isValid = false
    // }
    // if (!previous_school_name) {
    //   validations.previous_school_name =
    //     'Please Enter student previous school name'
    //   isValid = false
    // }

    // if (!source_of_enquiry) {
    //   validations.source_of_enquiry = 'Please select any one'
    //   isValid = false
    // }

    // if (!reference_by) {
    //   validations.reference_by = 'Please select any one'
    //   isValid = false
    // }

    if (!std_address) {
      validations.std_address = 'Please Enter student address'
      isValid = false
    }

    if (!isValid) {
      setValidations(validations)
    }

    return isValid
  }

  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = e => {
    e.preventDefault()

    const isValid = validateAll()

    if (!isValid) {
      return false
    }
    http.post('api/admission/store', values).then(res => {
      console.log(res)
      if (res.data.isok == false) {
        alert("error# "+res.data.error);
      }
      else {
        alert('Succefully')
        clearData();
      }
    })
  }

  const {
    acandemic_year,
    admission_class,
    sms_number,
    email,
    student_name,
    father_name,
    surname,
    marksheet_std_name,
    gender,
    std_bod,
    std_mother_name,
    previous_school_name,
    source_of_enquiry,
    reference_by,
    std_address
  } = values

  const validateOne = e => {
    const { name } = e.target
    const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
    const value = values[name]
    let message = ''

    if (!value) {
      message = `${name} is required`
    }

    if (
      value &&
      name === 'sms_number' &&
      !/[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value)
    ) {
      message = 'Phone Number must contain 10 number digits'
    }

    if (value && name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
      message = 'Email format must be as example@mail.com'
    }

    setValidations({ ...validations, [name]: message })
  }

  const {
    acandemic_year: acandemicyearVal,
    admission_class: admissionyearVal,
    sms_number: smsnumberVal,
    email: emailVal,
    student_name: studentnameVal,
    father_name: fathernameVal,
    surname: surnamerVal,
    marksheet_std_name: marksheetstdnameVal,
    gender: genderVal,
    std_bod: stdbodVal,
    std_mother_name: stdmothernameVal,
    previous_school_name: previousschoolnameVal,
    source_of_enquiry: sourceofenquiryVal,
    reference_by: referencebyVal,
    std_address: stdaddressVal
  } = validations

  const clearData = () => {
    setValues({
      acandemic_year: '',
      admission_class: '',
      sms_number: '',
      email: '',
      student_name: '',
      father_name: '',
      surname: '',
      marksheet_std_name: '',
      gender: '',
      std_bod: '',
      std_mother_name: '',
      previous_school_name: '',
      source_of_enquiry: '',
      reference_by: '',
      std_address: ''
    });
  }

  return (
    <div>
      <section className='contact-one dup'>
        <div className='container-fluid'>
          <h2 className='contact-one__title text-center bg-primary'>
            Admission Inquiry
          </h2>
          <Formik>
            <Form
              id='registrationform'
              className='contact-one__form contact-form-validated'
              onSubmit={handleSubmit}
            >
              <div className='row low-gutters'>
                <div className='col-sm-3'>
                  <label class="form-label required">Select Academic Year</label>
                  <select
                    className='form-control'
                    name='acandemic_year'
                    value={acandemic_year}
                    onChange={handleChange}
                  >
                    <option value='2022-23'>2022-23</option>
                    <option value='2023-24'>2023-24</option>
                    <option value='2024-25'>2024-25</option>
                  </select>
                  <div className='error'>{acandemicyearVal}</div>
                </div>
                <div className='col-sm-3'>
                <label class="form-label required">Admission Class</label>
                  <select
                    className='form-control'
                    name='admission_class'
                    value={admission_class}
                    onChange={handleChange}
                    required
                  >
                    <option>Select Class</option>
                    <option value="Nursery">Nursery</option>
                    <option value="Jr.KG">Jr.KG</option>
                    <option value="Sr.KG">Sr.KG</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <div className='error'>{admissionyearVal}</div>
                </div>
                <div className='col-sm-3'>
                <label class="form-label required">SMS Number(Communication)</label>
                  <input
                    type='number'
                    name='sms_number'
                    placeholder='Enter SMS Number'
                    autoComplete='off'
                    value={sms_number}
                    onChange={handleChange}
                    required
                  />
                  <div className='error'>{smsnumberVal}</div>
                </div>
                <div className='col-sm-3'>
                <label class="form-label required">Email  Id(Communication)</label>
                  <input
                    type='email'
                    name='email'
                    placeholder='Enter Email Id'
                    autoComplete='off'
                    onChange={handleChange}
                    value={email}
                  />
                  <div className='error'>{emailVal}</div>
                </div>
                <div className='col-sm-4'>
                <label class="form-label required">Student Name/First Name</label>
                  <input
                    type='text'
                    name='student_name'
                    placeholder='Enter Student Name/First Name'
                    autoComplete='off'
                    value={student_name}
                    onChange={handleChange}
                  />
                  <div className='error'>{studentnameVal}</div>
                </div>
                <div className='col-sm-4'>
                <label class="form-label required">Father Name/Middle Name</label>
                  <input
                    type='text'
                    name='father_name'
                    placeholder='Enter Father Name/Middel Name'
                    autoComplete='off'
                    value={father_name}
                    onChange={handleChange}
                  />
                  <div className='error'>{fathernameVal}</div>
                </div>
                <div className='col-sm-4'>
                <label class="form-label required">Surname/Last Name</label>
                  <input
                    type='text'
                    name='surname'
                    placeholder='Enter Surname/Last Name'
                    autoComplete='off'
                    value={surname}
                    onChange={handleChange}
                  />
                  <div className='error'>{surnamerVal}</div>
                </div>
                <div className='col-sm-10'>
                <label class="form-label ">Student Full Name As Per Marksheet</label>
                  <input
                    type='text'
                    name='marksheet_std_name'
                    placeholder='Enter Student Full Name As Per Marksheet'
                    autoComplete='off'
                    value={marksheet_std_name}
                    onChange={handleChange}
                  />
                  <div className='error'>{marksheetstdnameVal}</div>
                </div>
                <div className='col-sm-4'>
                <label class="form-label required">Gender</label>
                  <select
                    className='form-control'
                    name='gender'
                    value={gender}
                    required
                    onChange={handleChange}
                  >
                    <option>Select Gender</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                  </select>
                  <div className='error'>{genderVal}</div>
                </div>
                <div className='col-sm-4'>
                <label class="form-label required">Date Of Birth</label>
                  <input
                    type='date'
                    name='std_bod'
                    value={std_bod}
                    onChange={handleChange}
                  />
                  <div className='error'>{stdbodVal}</div>
                </div>
                <div className='col-sm-4'>
                <label class="form-label">Mother's Name</label>
                  <input
                    type='text'
                    name='std_mother_name'
                    placeholder='Enter Mother Name'
                    autoComplete='off'
                    value={std_mother_name}
                    onChange={handleChange}
                  />
                  <div className='error'>{stdmothernameVal}</div>
                </div>
                <div className='col-sm-6' >
                <label class="form-label">Present School Name</label>
                  <input
                    type='text'
                    name='previous_school_name'
                    placeholder='Enter Previous School Name'
                    autoComplete='off'
                    value={previous_school_name}
                    onChange={handleChange}
                  />
                  <div className='error'>{previousschoolnameVal}</div>
                </div>
                <div className='col-sm-6'>
                <label class="form-label">Residential Address</label>
                  <input
                    type='text'
                    placeholder='Please Enter Address..'
                    name='std_address'
                    autoComplete='off'
                    value={std_address}
                    required
                    onChange={handleChange}
                  />
                  <div className='error'>{stdaddressVal}</div>
                </div>
                <div className='col-sm-6'>
                <label class="form-label">Source Of Enquiry</label>
                  <select
                    className='form-control'
                    name='source_of_enquiry'
                    value={source_of_enquiry}
                    onChange={handleChange}
                  >
                    <option>Select Source Of Enquiry</option>
                    <option value='call'>call</option>
                    <option value='sms'>sms</option>
                  </select>
                  <div className='error'>{sourceofenquiryVal}</div>
                </div>
                <div className='col-sm-6'>
                <label class="form-label">Reference By</label>
                  <select
                    className='form-control'
                    name='reference_by'
                    value={reference_by}
                    onChange={handleChange}
                  >
                    <option>Select Reference By</option>
                    <option value='friends'>Friends</option>
                    <option value='News'>News</option>
                    <option value='SAS Teacher'>SAS Teacher</option>
                    <option value='Leaflet'>Leaflet</option>
                    <option value='Social Media'>Social Media</option>
                    <option value='SAS Parent'>SAS Parent</option>
                  </select>
                  <div className='error'>{referencebyVal}</div>
                </div>
                <div className='col-lg-12'>
                  <div className='text-center'>
                    <button type='submit' className='thm-btn'>
                      Apply
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type='reset' className='thm-btn' onClick={() => clearData()}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
          <div className='result text-center'></div>
        </div>
      </section>
    </div>
  )
}

export default AdmissionForm
